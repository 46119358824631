<template>
    <div>
        <b-modal
            id="edit-early-access-modal"
            no-close-on-backdrop
            hide-footer
            size="md"
            centered
            :title="'Give Early Access to ' + grade_name"
            v-b-modal.edit-early-access-modal
            @hidden="resetModal"
            @shown="onModalOpen"
        >
            <div class="modal_content">
                <p class="mb-0">Select App Users</p>
                <v-select
                    class="w-100 mb-1"
                    style="margin-top: 5px"
                    v-model="selectedUsers"
                    multiple
                    :options="listUsers"
                    label="name"
                    @search="getUsers"
                    :filterable="false"
                >
                </v-select>

                <div class="modal-footer px-0">
                    <b-button
                        @click="saveBtn"
                        :disabled="loading"
                        variant="primary"
                    >
                        <b-spinner v-if="loading" small />
                        Submit
                    </b-button>
                </div>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BButton,
    BFormInput,
    BInputGroup,
    BFormGroup,
    BAlert,
    BSpinner,
} from "bootstrap-vue";
import axios from "axios";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
    components: {
        BButton,
        BFormInput,
        BInputGroup,
        BFormGroup,
        BAlert,
        BSpinner,
        ToastificationContent,
        vSelect,
    },
    data() {
        return {
            baseVuePath: "@view/src/views/components/themes/",
            baseReactNativePath: "@src/components/themes/",
            vuePath: "@view/src/views/components/themes/",
            reactNativePath: "@src/components/themes/",
            errorMsg: "",
            loading: false,
            alertMessageToast: "",
            record: {},
            users: [],
            selectedUsers: [],
        };
    },
    computed: {
        hasError() {
            return this.errorMsg != "";
        },
        listUsers() {
            return this.users;
        },
    },
    watch: {
        grade_data: function (newVal) {
            this.getUsers(null);
        },
    },
    methods: {
        onModalOpen() {
            if (this.edit_grade && this.edit_grade.users) {
                this.edit_grade.users.forEach((user) => {
                    this.selectedUsers.push(user);
                });
            } else {
                this.selectedUsers = [];
            }
        },
        async getUsers(item) {
            this.loading = true;
            try {
                const resp = await axios.post(
                    process.env.VUE_APP_API_URL + "/app-user/all",
                    { search: item },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                );

                const newUsers = resp.data.users;
                const mergedUsers = [
                    ...new Map(
                        [...this.selectedUsers, ...newUsers].map((user) => [
                            user._id,
                            user,
                        ])
                    ).values(),
                ];

                this.$nextTick(() => {
                    this.users = mergedUsers;
                });

                this.loading = false;
            } catch (error) {
                this.loading = false;
                console.error("Error fetching users:", error);
            }
        },

        showToast(variant) {
            this.$toast({
                component: ToastificationContent,
                props: {
                    title: "Notification",
                    icon: "BellIcon",
                    text: this.alertMessageToast,
                    variant,
                },
            });
        },
        resetModal() {
            this.selectedUsers = [];
            this.users = [];
        },
        async saveBtn() {
            this.loading = true;
            try {
                const resp = await axios.post(
                    process.env.VUE_APP_API_URL + "/assign/grade/user",
                    {
                        grade_id: this.grade_data._id,
                        user_ids: this.selectedUsers.map((user) => user._id),
                    },
                    {
                        headers: {
                            Authorization:
                                "Bearer " + localStorage.getItem("_t"),
                        },
                    }
                );
                this.alertMessageToast = resp.data.message;
                this.showToast("success");
                this.selectedUsers = [];
                this.$emit("getData");
                this.$bvModal.hide("edit-early-access-modal");
                this.loading = false;
            } catch (error) {
                this.loading = false;
                this.alertMessageToast = this.errorMsg;
                this.showToast("danger");
            }
        },
    },
    props: ["grade_data", "edit_early_access", "edit_grade", "grade_name"],
};
</script>
